//src/App.js
import React, { useState, useEffect } from "react";
 
function Pruebas() {
  const [country, setCountry]= useState([]);
  const [countryid, setCountryid]= useState('');
  const [stetes, setSat]= useState([]);
 
  useEffect( ()=>{
   const getcountry= async ()=>{
     const req= await fetch("https://parqueoenvia.com/apiParqueoenvia/zonas/country.php");
     const getres= await req.json();
     console.log(getres);
     setCountry(await getres);
 
   }
   getcountry();
 
 
  },[]);
 
  const handlecountry=(event)=>{
    const getcoutryid= event.target.value;
    setCountryid(getcoutryid);
    event.preventDefault();
  }
 
  useEffect( ()=>{
 
    const getstate= async ()=>{
      const resstate= await fetch(`https://parqueoenvia.com/apiParqueoenvia/zonas/state.php?id=${countryid }`);
      const getst= resstate.json();
      console.log(getst);
      setSat(await getst);
 
    }
    getstate();
 
  },[countryid]);
 
    
  return (
    <div className="container">
     <div className="row">
       <div className="col-sm-12">
         <h5 className="mt-4 mb-4 fw-bold">ReactJs PHP Mysqli Select Country State Dropdown Select Box</h5>
            
             <div className="row mb-3">
                 <div className="form-group col-md-4">
                 <label className="mb-2">Country</label>
                 <select name="country" className="form-select" onChange={(e)=>handlecountry(e)}>
                   <option>--Select Country--</option>
                   {
                     country.map( (getcon)=>(
                   <option key={getcon.id} value={getcon.id }> { getcon.country}</option>
                     ))
                }
                  
                 </select>
               </div>
               <div className="form-group col-md-4">
               <label className="mb-2">State</label>
               <select name="state" className="form-select">
                   <option>--Select State--</option>
                   {
                     stetes.map( (st,index)=>(                    
                   <option key={index} value={st.id}>{ st.name}</option>
                     ))
                     }
                 </select>
               </div>
 
               <div className="form-group col-md-2 mt-4">              
               <button className="btn btn-success mt-2" >Submit</button>               
               </div>
            </div>
                
       </div>
     </div>
    </div>
  );
}
export default Pruebas;