import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  name: "",
  documento: "",
  celular: "",
  direccion: "",
  email: "",
  clave: ""
};

function Notificaciones () {

  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/supervisores/notificaciones_supervisores.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    name:'',
    documento:'',
    celular:'',
    direccion:'',
    email:'',
    clave:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("direccion", frameworkSeleccionado.direccion);
    f.append("email", frameworkSeleccionado.email);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
         framework.celular = frameworkSeleccionado.celular;
         framework.email= frameworkSeleccionado.email;
         framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("direccion", frameworkSeleccionado.direccion);
    f.append("email", frameworkSeleccionado.email);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }


  /*const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }



  useEffect(()=>{
    

   //const interval = setInterval(() => {
      
       peticionGet();
       
     //}, 20000);
 
   //return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      filtering: false
      
    },
    
    {
      title:"FECHA REPORTE",
      field: "fecha_vehiculo"
    },

      {
        title:"ZONA_PARQUEOENVIA",
        field: "confirmar_zona_v"
       
      },

      {
        title:"SUB_ZONA",
        field: "name_v"
      },
      {
        title:'TIPO VEHICULO',
        field:'t_vehiculo'
      },

      {
        title:'NOMBRE_CLIENTE',
        field:'nombre'
      },

      {
        title:'DOCUMENTO_CLIENTE',
        field:'documento'
      },

      {
        title:'CELULAR_CLIENTE',
        field:'celular'
      },

      
      {
        title:'PLACA',
        field:'placa',
       // type: "date",
       // dateSetting: { locale: "es-CO" },
      //  filterComponent: (props) => <CustomDatePicker {...props} />
      },

      {
        title:'NOMBRE_OPERADOR',
        field:'name_operador'
      },

      {
        title:'DOCUMENTO_OPERADOR',
        field:'documento_operador'
      },


      {
        title:'CELULAR_OPERADOR',
        field:'celular_operador'
      },

      {
        title:'NOMBRE_SUPERVISOR',
        field:'nombre_supervisor'
      },

      {
        title:'DOCUMENTO_SUPERVISOR',
        field:'documento_supervisor'
      },

      {
        title:'OBSERVACIONES',
        field:'observaciones_supervisor'
      },

    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">



{/*
<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>OPERADORES SUSPENDIDOS</h4>
<i class="fa fa-users fa-2x" aria-hidden="true"></i>
</div>
<div class="stats-link">
<Link  to="/Operadores_suspendidos" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">SUPERVISORES</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable
 
columns={columnas}
data={data}
title= {<><i class="fas fa-user-friends fa-2x"></i> </> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'airplay',
        tooltip: 'ver',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },

     /* {
        icon: 'refresh',
        tooltip: 'Refresh Data',
        isFreeAction: true,
        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
      }*/
    
      /*
  {
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
 */
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
  exportMenu: [
    {
      label: "Exportar PDF",
      //// You can do whatever you wish in this function. We provide the
      //// raw table columns and table data for you to modify, if needed.
      // exportFunc: (cols, datas) => console.log({ cols, datas })
      exportFunc: (cols, datas) => ExportPdf(cols, datas, "SUPERVISORES"),
    },
    {
      label: "Exportar CSV",
      exportFunc: (cols, datas) => ExportCsv(cols, datas, "SUPERVISORES"),
    },
  ],
 // exportButton: true,
 columnsButton: true,
 sorting: true,
 
       thirdSortClick: true,
  filtering: true,
    actionsColumnIndex: -1,
 actionsCellStyle: {
   //backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar el visor {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-users"></i> <font Size="2">NOTIFICACIÓN</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-12 ">
   
     <label>Novedad:</label>
     <br/>
     <textarea type="text" row={5}   className="form-control" name="observaciones_supervisor" onChange={handleChange} 
    value={ frameworkSeleccionado && frameworkSeleccionado.observaciones_supervisor} readOnly/>
     
     </div>

  
     <div class="col-12">
        <br/>
     <label>Foto registro:</label>
     <br/>
  <center><img  onChange={handleChange} src={"https://parqueoenvia.com/apiParqueoenvia/supervidores_img/Imagenes/"+frameworkSeleccionado.foto_supervisores}  width="450x" /></center>
     <br/>
     </div>
            
  
  
     <div class="col-4 ">
     <label>Recarga:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="valor_t" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.valor_t} readOnly  />
     <br/>
     </div>

  

   
     <div class="col-5">
     <label>Documento:</label>
     <br/>
     <input type="text" className="form-control" name="documento" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.documento}  readOnly/>
     <br/>
     </div>

     <div class="col-3">
     <label>Tiempo:</label>
     <br/>
     <input type="text" className="form-control" name="valor" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.valor}  readOnly/>
     <br/>
     </div>

     <div class="col-12 ">
  
  <label>Email:</label>
  <br/>
  <input type="text" className="form-control" name="email" autofocus onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email}/>
  
  </div>

  <div class="col-6 ">
  <br/>
  <label>Fecha de ingreso recarga:</label>
  <br/>
  <input type="text" className="form-control" name="expDate" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate} readOnly/>
  
  </div>

  <div class="col-6 ">
  <br/>
  <label>Fecha de terminación recarga:</label>
  <br/>
  <input type="text" className="form-control" name="expDate2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate2} readOnly/>
  
  </div>

  <div class="col-6 ">
  <br/>
  <label>Nombres completos:</label>
  <br/>
  <input type="text" className="form-control" name="nombre" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nombre} readOnly/>
  
  </div>


  <div class="col-6 ">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="text" className="form-control" name="celular" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.celular} readOnly/>
     
     </div>

     <div class="col-6 ">
     <br/>
     <label>Vehículo:</label>
     <br/>
     <input type="text" className="form-control" name="t_vehiculo" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.t_vehiculo} readOnly/>
     
     </div>

     <div class="col-6">
     <br/>
     <label>Placa:</label>
     <br/>
     <input type="text" className="form-control" name="placa" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.placa} readOnly/>
     
     </div>


     <div class="col-6 ">
        <br/>
     <label>Zona:</label>
     <br/>
     <input type="text" className="form-control" name="confirmar_zona_v" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.confirmar_zona_v} readOnly/>
     
     </div>


     <div class="col-12 ">
        <br/>
     <label>Sub-zona:</label>
     <br/>
     
     <textarea type="text" className="form-control" name="name_v" cols="3" rows="5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name_v} readOnly/>
     </div>


     <div class="col-12 ">
        <br/>
     <label>Nombre operador que lo proceso:</label>
     <br/>
     
     <text type="text" className="form-control" name="name_operador" onChange={handleChange} placeholder="El usuario recargo" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} readOnly/>
     </div>


     <div class="col-12 ">
        <br/>
     <label>Documento operador:</label>
     <br/>
     
     <text type="text" className="form-control" name="name_operador"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.documento_operador} readOnly/>
     </div>

     { frameworkSeleccionado.v1 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v2 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v3 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v3} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v4 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v5 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v6 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v7 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v7" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v8 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v8" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v9 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v9" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v10 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v10" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v11 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v11" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v12 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v12" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v13 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v13" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v14 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v14" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v15 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v15" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v16 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v16" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v17 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v17" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v18 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v18" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v19 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v19" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v20 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v20" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} readOnly/>
     
     </div>
 : ""
} 
    

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
  {/* <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "} */}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-users"></i> <font Size="2">Visor</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-8">

   <label>Visor:</label>
   <br/>
         <input type="text" className="form-control"  name="name" onChange={handleChange}
           />
     </div>

     <div class="col-4">
     <label>Documento</label>
     <br/>
     <input type="number" className="form-control" name="documento" onChange={handleChange}  />
     
     </div>
     
     
     <div class="col-4">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="number" className="form-control" name="celular" onChange={handleChange}  />
     
     </div>
     
     <div class="col-8">
     <br/>
     <label>Dirección:</label>
     <br/>
     <input type="text" name="direccion" class="form-control"  onChange={handleChange} />
     
                    
                 
                  <br />
     </div>

     <div class="col-7">
     <label>Email:</label>
     <br/>
     <input type="email" className="form-control" name="email" onChange={handleChange} 
      />
     
     </div>

     <div class="col-5">
     <label>Contraseña:</label>
     <br/>
     <input type="password"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
      />
      <label>Mínimo 8 caracteres</label>
     <br/>
     </div>
     
    

     </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Notificaciones
