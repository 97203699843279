import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  name: "",
  documento: "",
  celular: "",
  direccion: "",
  email: "",
  clave: ""
};

function Operadores () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/operadores/operadores.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    name:'',
    documento:'',
    celular:'',
    direccion:'',
    email:'',
    clave:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("direccion", frameworkSeleccionado.direccion);
    f.append("email", frameworkSeleccionado.email);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
         framework.celular = frameworkSeleccionado.celular;
         framework.email= frameworkSeleccionado.email;
         framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("direccion", frameworkSeleccionado.direccion);
    f.append("email", frameworkSeleccionado.email);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }


  /*const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"OPERADOR",
      field: "name"
    },

    {
      title:'EMAIL',
      field:'email'
    },

      {
        title:"DOCUMENTO",
        field: "documento"
      },

      {
        title:"CELULAR",
        field: "celular"
      },
      {
        title:'DIRECCIÓN',
        field:'direccion'
      },

      
      {
        title:'TIPO USUARIO',
        field:'tipo_usuario'
      },

      {
        title:'ESTADO',
        field:'suspender'
      },

    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">



{/*
<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>OPERADORES SUSPENDIDOS</h4>
<i class="fa fa-users fa-2x" aria-hidden="true"></i>
</div>
<div class="stats-link">
<Link  to="/Operadores_suspendidos" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">OPERADORES</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-success" onClick={()=>abrirCerrarModalInsertar()}> <i class="fas fa-users fa-2x"></i> AGREGAR OPERADOR</button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'edit',
        tooltip: 'Editar',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  {
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar el operador {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-users"></i> <font Size="2">EDITAR OPERADOR</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-8">

<label>Operador:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
      value={frameworkSeleccionado && frameworkSeleccionado.name}  />
  </div>

  <div class="col-4">
  <label>Documento</label>
  <br/>
  <input type="number" className="form-control" name="documento" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.documento}/>
  
  </div>
  
  
  <div class="col-4">
  <br/>
  <label>Celular:</label>
  <br/>
  <input type="number" className="form-control" name="celular" onChange={handleChange} 
  value={frameworkSeleccionado && frameworkSeleccionado.celular} />
  
  </div>
  
  <div class="col-8">
  <br/>
  <label>Dirección:</label>
  <br/>
  <input type="text" name="direccion" class="form-control"  onChange={handleChange} 
  value={frameworkSeleccionado && frameworkSeleccionado.direccion}/>
  
                 
              
               <br />
  </div>

  <div class="col-7">
  <label>Email:</label>
  <br/>
  <input type="email" className="form-control" name="email" onChange={handleChange} 
   value={frameworkSeleccionado && frameworkSeleccionado.email} readOnly/>
  
  </div>

  <div class="col-5">
  <label>Contraseña:</label>
  <br/>
  <input type="password"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
  value={frameworkSeleccionado && frameworkSeleccionado.clave}
   />
   <label>Mínimo 8 caracteres</label>
  <br/>
  </div>

  
    

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-users"></i> <font Size="2">OPERADOR</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-8">

   <label>Operador:</label>
   <br/>
         <input type="text" className="form-control"  name="name" onChange={handleChange}
           />
     </div>

     <div class="col-4">
     <label>Documento</label>
     <br/>
     <input type="number" className="form-control" name="documento" onChange={handleChange}  />
     
     </div>
     
     
     <div class="col-4">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="number" className="form-control" name="celular" onChange={handleChange}  />
     
     </div>
     
     <div class="col-8">
     <br/>
     <label>Dirección:</label>
     <br/>
     <input type="text" name="direccion" class="form-control"  onChange={handleChange} />
     
                    
                 
                  <br />
     </div>

     <div class="col-7">
     <label>Email:</label>
     <br/>
     <input type="email" className="form-control" name="email" onChange={handleChange} 
      />
     
     </div>

     <div class="col-5">
     <label>Contraseña:</label>
     <br/>
     <input type="password"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
      />
      <label>Mínimo 8 caracteres</label>
     <br/>
     </div>
     
    

     </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Operadores
