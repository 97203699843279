import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';

import Bien from '../../assets/login/bien.png'


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  modulo: "",
  saldo: "",
  deporte: "",
  subdeporte: "",
  pais: "",
  departamento: "",
  ciudad: ""
};

function Modificar_recarga () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/recargas/recargas_realizadas.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    name:'',
    saldo:'',
    nit:'',
    ciudad:'',
    licencia:'',
    email:'',
    clave:'',
  });

  const [recuperar_pass, setRecuperar_pass] = useState("");
  const [loading, setLoading] = useState(false);
  function loadData(){
    setLoading(false);

    setTimeout(()=>{
        setLoading(true);
        //alert("No se pudo crear la indicacion")
    }, 100);
}

const recuperar_password= (e) => {
  // console.log(e.target.value) // si || no
  setRecuperar_pass(e.target.value)
}

  

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
   // await axios.get(baseUrlAd)
    await axios.get(`https://parqueoenvia.com/apiParqueoenvia/recargas/recargas_modificadas.php?idPuntoVenta=${global.id}`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

 

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("saldoActual",frameworkSeleccionado.saldoActual);
   /* f.append("name", frameworkSeleccionado.name);
    f.append("nit", frameworkSeleccionado.nit);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("licencia", frameworkSeleccionado.licencia);
    f.append("email", frameworkSeleccionado.email);*/
    f.append("METHOD", "PUT_recargas_realizadas");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
        framework.saldoActual= frameworkSeleccionado.saldoActual;
       /*  framework.name= frameworkSeleccionado.name;
         framework.ciudad= frameworkSeleccionado.ciudad;
         framework.licencia= frameworkSeleccionado.licencia;
         framework.email= frameworkSeleccionado.email;*/

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
     peticionPost();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("email", frameworkSeleccionado.email);
    f.append("name_puntoVenta", theUser.name);
    f.append("documento_puntoVenta", theUser.documento);
    f.append("saldoActual", frameworkSeleccionado.saldoActual);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalEliminar();
     //abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  /*const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/

  //console.log( "sumax" + (Number(5) + Number(frameworkSeleccionado.saldoActual)))


  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])


   

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"ANULADA",
      field: "anulada"
    },

    {
      title:"CLIENTE",
      field: "name"
    },

    
   

      {
        title:"DOCUMENTO",
        field: "documento"
      },

      {
        title:'TELÉFONO',
        field:'celular'
      },

      {
        title:'RECARGA',
        field:'saldoActual'
      
      },

      {
        title:'EMAIL',
        field:'email'
      },

      
      {
        title:'FECHA RECARGA',
        field:'tiempo'
      },

      
    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="puntoVenta")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>

{/*
<div class="row">




<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>PUNTOS SUSPENDIDOS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

</div>
  */}

  
  {
    //suma
   console.log(global.ok =  (Number(frameworkSeleccionado.saldo) + Number(frameworkSeleccionado.saldoActual)))
  }


<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">RECARGAS REALIZADAS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= { <><i class="fa fa-ban fa-2x"></i> ANULAR RECARGAS</> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'gavel',
        tooltip: 'Modificar Recarga',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
  <h4>Recarga realizada exitosamente a {frameworkSeleccionado && frameworkSeleccionado.name}!</h4>

  <div className="col-sm-12">
        {recuperar_pass === "MENSAJE EMAIL" && (
            <>
            <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/recargas.php" target="_parent" name="reset" noValidate>
          <div className="form-floating mb-15px">
          <input name="email" type="email" className="form-control h-45px fs-13px" placeholder="Usuario"
            autoComplete="off" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email} readOnly/>
          <label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600" >Email</label>
          </div>
         
          <div className="mb-15px">
          <button type="submit" onClick={loadData} disabled={loading} className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">
          <i class="fa fa-envelope-open" aria-hidden="true"></i>
       &nbsp;Enviar Notificación Correo</button>
          </div>
        

        

 
          </form>
          </>
          
        )}
      </div>
   </ModalBody>
   <center><div><img src={Bien} width="140px" /></div></center><br/>

   
   <ModalFooter>
   
    
     <i class="fa fa-envelope-open fa-2x" aria-hidden="true"></i><input
            type="button"
           
            value="MENSAJE EMAIL"    
            className="btn btn-success"    
            onClick={recuperar_password}
          /> 
    
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         Aceptar
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"orange",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">ANULAR RECARGA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/anular_recarga.php" target="_parent" name="reset" noValidate>

  <center><div class="col-6 alert alert-lime alert-dismissible fade show ">
     <label>ANULAR RECARGA:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="saldoActual" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.saldoActual} readOnly />
     <br/>
     </div>

     </center> 

    
    <div className='row'>
     <div class="col-7">
     <label>Nombre cliente:</label>
     <br/>
     <input type="text" className="form-control" name="name" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.name}  readOnly/>
     
     <br/>
     </div>

     <div class="col-5">
     <label>Documento</label>
     <br/>
     <input type="number" className="form-control" name="documento" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.documento} readOnly  />
     
     </div>
     <div class="col-4">
     <label>Teléfono:</label>
     <br/>
     
     <input type="number" className="form-control" name="celular" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.celular} readOnly />
     </div>
   
     <div class="col-8">
     <label>Email:</label>
     <br/>
     <input type="email" name="email" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email} readOnly />
     
     </div>          
                 
                  <br />
     </div>
<div style={{display:"none"}}>
     <div class="col-12 ">
      <center><p>--------------- Datos Punto de Ventas ---------------</p></center>
     </div>
     <div class="col-7">
     <label>Nombre puntoVenta:</label>
     <br/>
     <input type="text" className="form-control" name="id_puntoventa" onChange={handleChange} value={theUser.id} readOnly/>
     <input type="text" className="form-control" name="name_puntoVenta" onChange={handleChange} value={theUser.name} readOnly/>
     <input type="text" className="form-control" name="idCliente" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.idCliente}  readOnly/>
     <input type="number" placeholder="0" className="form-control " name="id" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.id} readOnly  />
     
     </div>

     <div class="col-5 ">
     <label>Documento puntoVenta:</label>
     <br/>
     <input type="text" className="form-control" name="documento_puntoVenta" onChange={handleChange} value={theUser.documento} readOnly/>
     
     </div>

     </div>

<br/>
{ frameworkSeleccionado.anulada !="si" 
?
   <>
     <button className="btn btn-primary w-100" type="submit" onClick={loadData} disabled={loading}><i class="fa-solid fa-pen-to-square"></i> Anular Recarga</button>{" "}
   </>
     : ""
    }    

{ frameworkSeleccionado.anulada !="no" 
?
   <>
     <center><p className='btn btn-warning'>Recarga ya ha sido anulada</p></center>
   </>
     : ""
    }    
     </form>

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   
 <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">PUNTO DE VENTA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-8">

   <label>P. Venta:</label>
   <br/>
         <input type="text" className="form-control"  name="name" onChange={handleChange}  />
     </div>

     <div class="col-4">
     <label>NIT</label>
     <br/>
     <input type="number" className="form-control" name="nit" onChange={handleChange}  />
     
     </div>
     
     
     <div class="col-4">
     <br/>
     <label>Ciudad:</label>
     <br/>
     <select name="ciudad" class="form-control"  onChange={handleChange} >
     <option value='no' selected>Seleccione</option>
                    <option value="Ibague">Ibagué</option>
                    <option value="Espinal">Espinal</option>
                    
                  </select>  
                  <br />
     </div>
     
     <div class="col-8">
     <br/>
     <label>Dirección:</label>
     <br/>
     <input type="text" name="licencia" class="form-control"  onChange={handleChange} />
     
                    
                 
                  <br />
     </div>

     <div class="col-7">
     <label>Email:</label>
     <br/>
     <input type="email" className="form-control" name="email" onChange={handleChange}  />
     
     </div>

     <div class="col-5">
     <label>Contraseña:</label>
     <br/>
     <input type="password"  className="form-control" name="clave" onChange={handleChange}  />
     <br/>
     </div>
     
    

     </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Modificar_recarga
