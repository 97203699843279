import './App.css';
import {HashRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import MyContextProvider from './components/login/MyContext';
import history from './components/login/history';

import Inicio from './components/views/inicio/Inicio';
import Principal from './components/views/principal/Principal';
import Puntos_ventas from './components/views/puntos_ventas/Puntos_ventas';
import Operadores from './components/views/operadores/Operadores';
import Supervisores from './components/views/supervisores/Supervisores';
import Autoridades from './components/views/autoridades/Autoridades';

import Recargar from './components/views/recargas/Recargar';
import Recargas_realizadas from './components/views/recargas/Recargas_realizadas';
import Anular_recarga from './components/views/recargas/Anular_recarga';

import Tarifas from './components/views/tarifas/Tarifas';

import Pruebas from './components/views/zonas/Pruebas';
import Zonas_azules from './components/views/zonas/Zonas_azules';
import Sub_zonas from './components/views/zonas/Sub_zonas';
import Asignar_bahias from './components/views/zonas/Asignar_bahias';
import Modificar_bahias from './components/views/zonas/Modificar_bahias';

import Visores from './components/views/visores/Visores';

import Notificaciones from './components/views/supervisores/Notificaciones';

import Clientes from './components/views/clientes/Clientes';

import Informe_general from './components/views/informes/Informe_general';

import Ver_publicidad from './components/views/publicidad/Ver_publicidad';

function App() {
  return (
    <MyContextProvider>
    <Router>
     <Inicio/>
     <Routes>
     <Route path="/Principal" element = {<Principal/>}></Route>
     <Route path="/Puntos_ventas" element = {<Puntos_ventas/>}></Route>
     <Route path="/Operadores" element = {<Operadores/>}></Route>
     <Route path="/Supervisores" element = {<Supervisores/>}></Route>
     <Route path="/Autoridades" element = {<Autoridades/>}></Route>

     <Route path="/Recargar" element = {<Recargar/>}></Route>
     <Route path="/Recargas_realizadas" element = {<Recargas_realizadas/>}></Route>
     <Route path="/Tarifas" element = {<Tarifas/>}></Route>
     <Route path="/Pruebas" element = {<Pruebas/>}></Route>
     <Route path="/Zonas_azules" element = {<Zonas_azules/>}></Route>
     <Route path="/Sub_zonas" element = {<Sub_zonas/>}></Route>
     <Route path="/Asignar_bahias" element = {<Asignar_bahias/>}></Route>
     <Route path="/Modificar_bahias" element = {<Modificar_bahias/>}></Route>

     <Route path="/Anular_recarga" element = {<Anular_recarga/>}></Route>

     <Route path="/Visores" element = {<Visores/>}></Route>
     <Route path="/Notificaciones" element = {<Notificaciones/>}></Route>

     <Route path="/Clientes" element = {<Clientes/>}></Route>

     <Route path="/Informe_general" element = {<Informe_general/>}></Route>

     <Route path="/Ver_publicidad" element = {<Ver_publicidad/>}></Route>
    
     <Route history={history}></Route>
     <Route path="/" element={<Navigate replace to="/Principal" />}></Route>
     </Routes>
     </Router>
 </MyContextProvider>
  );
}

export default App;
