import React, {useContext,useState} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';
import Login from '../../login/Login';

import Parking from '../../assets/login/parking.jpg';

function Principal () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

    const {toggleNav,registerUser} = useContext(MyContext);
  

    
    if(isAuth)
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">BIENVENIDOS /A</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">

<form>

<div class="col-md-12">

<div class="col-md-12">
<img src={Parking} width="100%" />

</div>
</div>



</form>
</div>



</div>

</div>




</div>


</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Principal
