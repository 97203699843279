import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  name: "",
  country: "",
  confirmar_zona: "",
  estado: "Procesado",
  v1:"",
  v2:"",
  v3:"",
  v4:"",
  v5:"",
  v6:"",
  v7:"",
  v8:"",
  v9:"",
  v10:"",
  v11:"",
  v12:"",
  v13:"",
  v14:"",
  v15:"",
  v16:"",
  v17:"",
  v18:"",
  v19:"",
  v20:""
};

function Asignar_bahias () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

  const [country, setCountry]= useState([]);
  const [countryid, setCountryid]= useState('');
  const [stetes, setSat]= useState([]);

  


 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/zonas/vahias.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    country:'',
    confirmar_zona:'',
    estado:'proceado',
    v1:'',
    v2:'',
    v3:'',
    v4:'',
    v5:'',
    v6:'',
    v7:'',
    v8:'',
    v9:'',
    v10:'',
    v11:'',
    v12:'',
    v13:'',
    v14:'',
    v15:'',
    v16:'',
    v17:'',
    v18:'',
    v19:'',
    v20:''
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("estado", frameworkSeleccionado.estado);
    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.estado= frameworkSeleccionado.estado;

       }
     });
     
     setData(dataNueva);
     abrirCerrarModalEditar();
     peticionPost();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("country", frameworkSeleccionado.country);
    f.append("confirmar_zona", frameworkSeleccionado.confirmar_zona);
    f.append("id_subzona", frameworkSeleccionado.id);
    f.append("v1", frameworkSeleccionado.v1);
    f.append("v2", frameworkSeleccionado.v2);
    f.append("v3", frameworkSeleccionado.v3);
    f.append("v4", frameworkSeleccionado.v4);
    f.append("v5", frameworkSeleccionado.v5);
    f.append("v6", frameworkSeleccionado.v6);
    f.append("v7", frameworkSeleccionado.v7);
    f.append("v8", frameworkSeleccionado.v8);
    f.append("v9", frameworkSeleccionado.v9);
    f.append("v10", frameworkSeleccionado.v10);
    f.append("v11", frameworkSeleccionado.v11);
    f.append("v12", frameworkSeleccionado.v12);
    f.append("v13", frameworkSeleccionado.v13);
    f.append("v14", frameworkSeleccionado.v14);
    f.append("v15", frameworkSeleccionado.v15);
    f.append("v16", frameworkSeleccionado.v16);
    f.append("v17", frameworkSeleccionado.v17);
    f.append("v18", frameworkSeleccionado.v18);
    f.append("v19", frameworkSeleccionado.v19);
    f.append("v20", frameworkSeleccionado.v20);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
    // abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }


  console.log("name " + frameworkSeleccionado.v1)

 /* const handlecountry=(event)=>{
    const getcoutryid= event.target.value;
    setCountryid(getcoutryid);
    event.preventDefault();
  } */

 /* const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/


  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])


   

  useEffect( ()=>{
    const getcountry= async ()=>{
      const req= await fetch("https://parqueoenvia.com/apiParqueoenvia/zonas/country.php");
      const getres= await req.json();
      console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();
  
  
   },[]);

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"SUB ZONA AZUL",
      field: "name"
    },

    {
        title:"ZONAS",
        field: "confirmar_zona"
      },

      {
        title:"ESTADO",
        field: "estado"
      },

   
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>




<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">ASIGNACÓN DE BAHÍAS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<i class="fa fa-map-pin fa-2x"></i>}
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'map',
        tooltip: 'Asignar Bahías',
       onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
      {
        icon : 'delete',
        tooltip: 'Eliminar',
         onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
      }
    
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar esta configuración de la zona y la sub-zona {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">ASIGNAR BAHÍAS</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">
   
     <div class="col-3">
     <label>Zona :</label>
     <br/>
     <input type="text" className="form-control" name="confirmar_zona" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.confirmar_zona} readOnly/>
     <br/>
     </div>

     <div class="col-9">
     <label>Sub - Zona</label>
     <br/>
     <input type="text" className="form-control" name="name" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name} readOnly/>
     
     </div>

     <div class="col-3">
     <label>Id - zona</label>
     <br/>
     <input type="text" className="form-control" name="country" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.country} readOnly/>
     
     </div>


     <div class="col-3">
     <label>Id - sub - zona</label>
     <br/>
     <input type="text" className="form-control" name="id_subzona" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.id} readOnly/>
     
     <br/>
     </div>




<div class="accordion" id="accordion">
<div class="accordion-item border-0">
<div class="accordion-header" id="headingOne">
<button class="accordion-button bg-gray-300 text-black px-3 py-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false">
<i class="fa fa-circle fa-fw text-blue me-2 fs-8px"></i> 1. Bahías
</button>
</div>
<div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordion">
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 1</b></label>
                  <input name="v1" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 2</b></label>
                  <input name="v2" className="form-control" type="text" placeholder="Bahía sin registrar" 
                   onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 3</b></label>
                  <input name="v3" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v3} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 4</b></label>
                  <input name="v4" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4}/>
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 5</b></label>
                  <input name="v5" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} />
                  <br/>
                  </div>

                  <div>

        
                  </div>


</div>
</div>
</div>
<div class="accordion-item border-0">
<div class="accordion-header" id="headingTwo">
<button class="accordion-button bg-gray-300 text-black px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false">
<i class="fa fa-circle fa-fw text-indigo me-2 fs-8px"></i> 2. Bahías
</button>
</div>
<div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordion" >
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 6</b></label>
                  <input name="v6" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 7</b></label>
                  <input name="v7" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 8</b></label>
                  <input name="v8" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 9</b></label>
                  <input name="v9" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} />
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 10</b></label>
                  <input name="v10" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10}/>
                  <br/>
                  </div>


</div>
</div>
</div>
<div class="accordion-item border-0">
<div class="accordion-header" id="headingThree">
<button class="accordion-button bg-gray-300 text-black px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false">
<i class="fa fa-circle fa-fw text-teal me-2 fs-8px"></i> 3. Bahías
</button>
</div>
<div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordion">
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 11</b></label>
                  <input name="v11" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 12</b></label>
                  <input name="v12" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 13</b></label>
                  <input name="v13" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 14</b></label>
                  <input name="v14" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} />
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 15</b></label>
                  <input name="v15" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15}/>
                  <br/>
                  </div>

</div>
</div>
</div>
<div class="accordion-item border-0">
<div class="accordion-header" id="headingFour">
<button class="accordion-button bg-gray-300 text-black px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false">
<i class="fa fa-circle fa-fw text-info me-2 fs-8px"></i> 4. Bahías
</button>
</div>
<div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordion">
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 16</b></label>
                  <input name="v16" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 17</b></label>
                  <input name="v17" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 18</b></label>
                  <input name="v18" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 19</b></label>
                  <input name="v19" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19}/>
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 20</b></label>
                  <input name="v20" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} />
                  <br/>
                  </div>
</div>
</div>
</div>






</div>


     

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">AGREGAR SUB-ZONA AZUL</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-12">

   <label>Sub zona azul:</label>
   <br/>
         <input type="text" className="form-control"  name="name"
         onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name}  />
       
     </div>

     <div class="col-3">
     <label>Id - zona</label>
     <br/>
     <input type="text" className="form-control" name="country" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.country} readOnly/>
     
     </div>


     <div class="col-3">
     <label>Id - sub - zona</label>
     <br/>
     <input type="text" className="form-control" name="id_subzona" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.id} readOnly/>
     
     <br/>
     </div>


     <div class="accordion" id="accordion">
<div class="accordion-item border-0">
<div class="accordion-header" id="headingOne">
<button class="accordion-button bg-gray-300 text-black px-3 py-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false">
<i class="fa fa-circle fa-fw text-blue me-2 fs-8px"></i> 1. Bahías
</button>
</div>
<div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordion">
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 1</b></label>
                  <input name="v1" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 2</b></label>
                  <input name="v2" className="form-control" type="text" placeholder="Bahía sin registrar" 
                   onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 3</b></label>
                  <input name="v3" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange}  />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 4</b></label>
                  <input name="v4" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4}/>
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 5</b></label>
                  <input name="v5" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} />
                  <br/>
                  </div>

                  <div>

        
                  </div>


</div>
</div>
</div>
<div class="accordion-item border-0">
<div class="accordion-header" id="headingTwo">
<button class="accordion-button bg-gray-300 text-black px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false">
<i class="fa fa-circle fa-fw text-indigo me-2 fs-8px"></i> 2. Bahías
</button>
</div>
<div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordion" >
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 6</b></label>
                  <input name="v6" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 7</b></label>
                  <input name="v7" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 8</b></label>
                  <input name="v8" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} />
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 9</b></label>
                  <input name="v9" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} />
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 10</b></label>
                  <input name="v10" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10}/>
                  <br/>
                  </div>


</div>
</div>
</div>
<div class="accordion-item border-0">
<div class="accordion-header" id="headingThree">
<button class="accordion-button bg-gray-300 text-black px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false">
<i class="fa fa-circle fa-fw text-teal me-2 fs-8px"></i> 3. Bahías
</button>
</div>
<div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordion">
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 11</b></label>
                  <input name="v11" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 12</b></label>
                  <input name="v12" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 13</b></label>
                  <input name="v13" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 14</b></label>
                  <input name="v14" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} />
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 15</b></label>
                  <input name="v15" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15}/>
                  <br/>
                  </div>

</div>
</div>
</div>
<div class="accordion-item border-0">
<div class="accordion-header" id="headingFour">
<button class="accordion-button bg-gray-300 text-black px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false">
<i class="fa fa-circle fa-fw text-info me-2 fs-8px"></i> 4. Bahías
</button>
</div>
<div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordion">
<div class="accordion-body bg-gray-100 text-black">

<div>
    <label><b>Bahía 16</b></label>
                  <input name="v16" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 17</b></label>
                  <input name="v17" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 18</b></label>
                  <input name="v18" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18}/>
                  <br/>
                  </div>


                  <div>
    <label><b>Bahía 19</b></label>
                  <input name="v19" className="form-control" type="text" placeholder="Bahía sin registrar" 
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19}/>
                  <br/>
                  </div>

                  <div>
    <label><b>Bahía 20</b></label>
                  <input name="v20" className="form-control" type="text" placeholder="Bahía sin registrar"
                  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} />
                  <br/>
                  </div>
</div>
</div>
</div>






</div>
     

     
     
  
    

     </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Asignar_bahias
