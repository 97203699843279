import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  name: "",
  country: "",
  confirmar_zona: "",
  id_subzona:"",
  id_unico_subzona:""
};

function Sub_zonas () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

  const [country, setCountry]= useState([]);
  //const [countryid, setCountryid]= useState('');

  const [country2, setCountry2]= useState([]);
  const [id_subzona, setId_subzona]= useState([]);

  const [id_unico_subzona, setId_unico_subzona]= useState([]);

  const [stetes, setSat]= useState([]);

  


 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/zonas/sub_zonas.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    country:'',
    confirmar_zona:'',
    id_subzona:'',
    id_unico_subzona:""
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
   // f.append("name", frameworkSeleccionado.name);
   f.append("id_subzona", frameworkSeleccionado.id_subzona);
   f.append("country", frameworkSeleccionado.country);
   f.append("country2", frameworkSeleccionado.country2);
   f.append("id_unico_subzona", frameworkSeleccionado.id_unico_subzona);
    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       //  framework.name= frameworkSeleccionado.name;
       framework.country= frameworkSeleccionado.country;
       framework.country2= frameworkSeleccionado.country2;
       framework.id_subzona= frameworkSeleccionado.id_subzona;
       framework.id_unico_subzona= frameworkSeleccionado.id_unico_subzona;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("country", frameworkSeleccionado.country);
    f.append("confirmar_zona", frameworkSeleccionado.confirmar_zona);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }


 /* const handlecountry=(event)=>{
    const getcoutryid= event.target.value;
    setCountryid(getcoutryid);
    event.preventDefault();
  } */

 /* const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/


  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])


   

  useEffect( ()=>{
    const getcountry= async ()=>{
      const req= await fetch("https://parqueoenvia.com/apiParqueoenvia/zonas/select_subzonas.php");
      const getres= await req.json();
     // console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();
  
  
   },[]);


  useEffect( ()=>{
    const getcountry2= async ()=>{
      const req2= await fetch("https://parqueoenvia.com/apiParqueoenvia/zonas/select_subzonas2.php");
      const getres2= await req2.json();
      setCountry2(await getres2);
  
    }
    getcountry2();
  
  
   },[]);


   useEffect( ()=>{
    const getid_subzona= async ()=>{
      const req3= await fetch("https://parqueoenvia.com/apiParqueoenvia/zonas/select_subzonas3.php");
      const getres3= await req3.json();
      setId_subzona(await getres3);
  
    }
    getid_subzona();


   },[]);


   useEffect( ()=>{
    const getid_unico_subzona= async ()=>{
      const req4= await fetch("https://parqueoenvia.com/apiParqueoenvia/zonas/select_subzonas3.php");
      const getres4= await req4.json();
      setId_unico_subzona(await getres4);
  
    }
    getid_unico_subzona();

  },[]);

  
   //console.log("mizona "+ global.zona)

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },

    {
        title:"USUARIO",
        field: "name"
      },

      {
        title:"CEDULA",
        field: "documento"
      },

      {
        title:"CELULAR",
        field: "celular"
      },

      {
        title:"EMAIL",
        field: "email"
      },

    {
        title:"ZONAS",
        field: "country2"
      },

    
    {
      title:"ZONA ASIGNADA",
      field: "country"
    },

    
      {
        title:"ROL",
        field: "tipo_usuario"
      },

   
    
  ];


  
  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>




<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">AGREGAR SUB-ZONAS A OPERARIOS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<i class="fa fa-street-view fa-2x"></i>}
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'accessibility',
        tooltip: 'Asignar sub-zona',
       onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      }
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar la sub zona {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">ASIGNAR SUB-ZONA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">
   
    

     <div className="row">

    <div className="col-12">
     <br/>
                 <label>Seleccionar Sub-zona:</label>
              {/*   <select name="country" className="form-select" onChange={(e)=>handlecountry(e)}> */}
              <select name="country" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar zona--</option>
                   {
                     country.map( (getcon)=>(
                      
                   <option key={getcon.id} value={getcon.name}> {"--zona:-- " +  getcon.confirmar_zona + "  --Subzona:-- " +  getcon.name}</option>
                
                     ))
                    
                }

               
                  
                 </select>

                 <label><div class="alert alert-success fade show" style={{marginBottom:"0rem", padding:"0.375rem .9375rem"}}>Selecciona esta Sub-zona para activar permisos a operador</div></label>
              
               
 
               
            </div> 


           <div className="col-12">
     <br/>
                 <label>Selecciona de nuevo  Sub-zona:</label>
            
              <select name="country2" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar zona--</option>
                   {
                     country2.map( (getcon2)=>(
                      
                   <option key={getcon2.id} value={getcon2.confirmar_zona}> {"--zona:-- " +  getcon2.confirmar_zona + "  --Subzona:-- " +  getcon2.name}</option>
                
                     ))
                    
                }

               
                  
                 </select>
                
                 <label><div class="alert alert-primary fade show" style={{marginBottom:"0rem", padding:"0.375rem .9375rem"}}>Selecciona esta Sub-zona para activar permisos administrador1</div></label>
 
               
            </div>  



            <div className="col-12">
     <br/>
                 <label>Selecciona de nuevo  Sub-zona:</label>
            
              <select name="id_subzona" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar zona--</option>
                   {
                     id_subzona.map( (getcon3)=>(
                    
                   <option key={getcon3.id}  value={getcon3.country}> {"--zona:-- " +  getcon3.confirmar_zona + "  --Subzona:-- " +  getcon3.name}</option>
                
                          
                   
                     ))
                    
                }
                  
                 </select>
              
                 <label><div class="alert alert-primary fade show" style={{marginBottom:"0rem", padding:"0.375rem .9375rem"}}>Confirma esta Sub-zona para activar permisos administrador2</div></label>
 
               
            </div> 




            <div className="col-12">
     <br/>
                 <label>Selecciona de nuevo  Sub-zona:</label>
            
              <select name="id_unico_subzona" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar zona--</option>
                   {
                     id_unico_subzona.map( (getcon4)=>(
                    
                   <option key={getcon4.id}  value={getcon4.id_subzona}> {"--zona:-- " +  getcon4.confirmar_zona + "  --Subzona:-- " +  getcon4.name}</option>
                
                          
                   
                     ))
                    
                }
                  
                 </select>
              
                 <label><div class="alert alert-primary fade show" style={{marginBottom:"0rem", padding:"0.375rem .9375rem"}}>Confirma esta Sub-zona para activar permisos para visualizadores!</div></label>
 
               
            </div> 

            

           

   
            </div> 

            <br/>

            <label>seleccione las mismas sub-zonas para poder asignarlas!</label>


   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Asignar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">AGREGAR SUB-ZONA AZUL</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
  {/* <div className="row">

   <div class="col-12">

   <label>Sub zona azul:</label>
   <br/>
         <input type="text" className="form-control"  name="name" onChange={handleChange}  />
       
     </div>

     
     
     
     



     <div className="col-6">
     <br/>
                 <label>Zona:</label>
             
              <select name="country" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar zona--</option>
                   {
                     country.map( (getcon)=>(
                      
                   <option key={getcon.id} value={getcon.id }> {getcon.country}</option>
                
                     ))
                    
                }

               
                  
                 </select>
              
               
 
               
            </div> 

            



            <div class="col-6">
   
     <label><br/>Confirmar zona:</label>
    
     <select name="confirmar_zona" class="form-control"  onChange={handleChange} >
     <option value='no' selected>--Seleccionar zona--</option>
                    <option value="Sur">Sur</option>
                    <option value="Centro">Centro</option>
                    <option value="Norte">Norte</option>
                    <option value="Occidente">Occidente</option>
                    
                  </select>  
                  <br />
     </div>

     <label>Las zonas deben ser iguales!</label>

     
  
    

     </div> */}
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Sub_zonas
