import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

import '../../App.css';
import {MyContext} from '../login/MyContext';

/*import LogoMenu from '../assets/plantilla/menu/servidor.gif';
import LogoUsuario from '../assets/plantilla/menu/user2-160x160.jpg';
import Usuarios from '../assets/plantilla/menu/usuarios.png';
import Crear_usuarios from '../assets/plantilla/menu/crear_usuarios.png';
import Ver_usuarios from '../assets/plantilla/menu/ver_usuarios.png';
import Compras from '../assets/plantilla/menu/compras.png';
import Ingresos from '../assets/plantilla/menu/ingresos.png';
import Proveedores from '../assets/plantilla/menu/proveedores.png';
import Ventas from '../assets/plantilla/menu/ventas.png';
import Ventas_submenu from '../assets/plantilla/menu/ventas_submenu.png';
import Clientes from '../assets/plantilla/menu/clientes.png';
import Inventarios from '../assets/plantilla/menu/inventarios.png';
import Categorias from '../assets/plantilla/menu/categorias.png';
import Articulos from '../assets/plantilla/menu/articulos.png';
import Reportes from '../assets/plantilla/menu/reportes.png';
import Reportes_submenu from '../assets/plantilla/menu/reportes_submenu.png';
import Kardex from '../assets/plantilla/menu/kardex.png';*/

function Menu(props) {
  const {rootState,logoutUser} = useContext(MyContext);
  
  const [dropdown, setDropdown]=useState(false);
  const {isAuth,theUser,showLogin} = rootState;

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
<>
    <div id="sidebar" className="app-sidebar">

    <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">
    
    <div className="menu">
    <div className="menu-profile">
    <a href="javascript:;" className="menu-profile-link" data-toggle="app-sidebar-profile" data-target="#appSidebarProfileMenu">
    <div className="menu-profile-cover with-shadow"></div>
    <center><div className="menu-profile-image">
    <img src="../assets/img/user/user-13.jpg" alt="" />
    </div></center>
    <div className="menu-profile-info">
    <div className="d-flex align-items-center">
    <div className="flex-grow-1">
    <a href="#"  style={{textDecoration:"none", color:"white"}}>{theUser.name}</a>
    </div>
    <div className="menu-caret ms-auto"></div>
    </div>
    <small>{theUser.documento}</small>
    </div>
    </a>
    </div>
    <div id="appSidebarProfileMenu" className="collapse">
    {/*<div className="menu-item pt-5px">
    <a href="javascript:;" className="menu-link">
    <div className="menu-icon"><i className="fa fa-cog"></i></div>
    <div className="menu-text">Settings</div>
    </a>
  </div> */}
    {/*<div className="menu-item">
    <a href="javascript:;" className="menu-link">
    <div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
    <div className="menu-text"> Send Feedback</div>
    </a>
</div>*/}
    <div className="menu-item pb-5px">
    <a href="javascript:;" className="menu-link" onClick={logoutUser} >
    <div className="menu-icon"><i className="fa fa-question-circle" style={{color:"red"}}></i></div>
    <div className="menu-text"> Cerrar Sesión</div>
    </a>
    </div>
    <div className="menu-divider m-0"></div>
    </div>
    <div className="menu-header">Menu</div>
    {/*<div className="menu-item has-sub active">
    <a href="javascript:;" className="menu-link">
    <div className="menu-icon">
    <i className="fa fa-sitemap"></i>
    </div>
    <div className="menu-text">Dashboard</div>
    <div className="menu-caret"></div>
    </a>
    <div className="menu-submenu">
    <div className="menu-item active">
    <a href="index.html" className="menu-link"><div className="menu-text">Dashboard v1</div></a>
    </div>
    <div className="menu-item">
    <a href="index_v2.html" className="menu-link"><div className="menu-text">Dashboard v2</div></a>
    </div>
    <div className="menu-item">
    <a href="index_v3.html" className="menu-link"><div className="menu-text">Dashboard v3</div></a>
    </div>
    </div>
  </div> */}

{ theUser.tipo_usuario =="admin"
     ?<>

    <div class="menu-item has-sub ">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-sitemap"></i>
</div>
<div class="menu-text">Usuarios</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Puntos_ventas" className="menu-link">
<div class="menu-text"> <i class="fa-brands fa-cc-diners-club" style={{color:"#B2B2B2"}}></i>&nbsp; Puntos de Venta</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Operadores" className="menu-link">
<div class="menu-text"> <i class="fas fa-users" style={{color:"#B2B2B2"}}></i> &nbsp;Operadores</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Supervisores" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-lock" style={{color:"#B2B2B2"}}></i>&nbsp; Supervisores</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Autoridades" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-tag" style={{color:"#B2B2B2"}}></i>&nbsp; Autoridades</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Visores" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-friends" style={{color:"#B2B2B2"}}></i>&nbsp; Visores</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Clientes" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-clock" style={{color:"#B2B2B2"}}></i>&nbsp; Clientes</div>
</Link>
</div>
{
/*
<div class="menu-item active">
<Link to="/Publicidad" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-clock" style={{color:"#B2B2B2"}}></i>&nbsp; Publicidad</div>
</Link>
</div>*/}

{/*
<div class="menu-item active">
<Link to="/Paquetes" className="menu-link">
<div class="menu-text"> <i class="fas fa-users-cog" style={{color:"#B2B2B2"}}></i>&nbsp; Paquetes</div>
</Link>
</div>

*/}
</div>
</div>
        
   
        </>

        : ""
        } 


{ theUser.tipo_usuario =="admin"
     ?<>

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-briefcase"></i>
</div>
<div class="menu-text">Publicidad</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Ver_publicidad" className="menu-link">
<div class="menu-text"> <i class="fa fa-camera" style={{color:"#B2B2B2"}}></i>&nbsp; Ver Publicidad</div>
</Link>

</div>


</div>
</div>
        
   
        </>

        : ""
        } 

{ theUser.tipo_usuario =="puntoVenta"
     ?<>

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-podcast"></i>
</div>
<div class="menu-text">Recargas</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Recargar" className="menu-link">
<div class="menu-text"> <i class="fa fa-users" style={{color:"#B2B2B2"}}></i>&nbsp; Recargar Cliente</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Recargas_realizadas" className="menu-link">
<div class="menu-text"> <i class="fa fa-list-ol" style={{color:"#B2B2B2"}}></i>&nbsp; Recargas Realizadas</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Anular_recarga" className="menu-link">
<div class="menu-text"> <i class="fa fa-pencil-square" style={{color:"#B2B2B2"}}></i>&nbsp; Anular Recarga</div>
</Link>
</div>
{/*<div class="menu-item active">
<Link to="/Operadores" className="menu-link">
<div class="menu-text"> <i class="fas fa-users" style={{color:"#B2B2B2"}}></i> &nbsp;Operadores</div>
</Link>
      </div>
<div class="menu-item active">
<Link to="/Supervisores" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-lock" style={{color:"#B2B2B2"}}></i>&nbsp; Supervisores</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Autoridades" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-tag" style={{color:"#B2B2B2"}}></i>&nbsp; Autoridades</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Visores" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-friends" style={{color:"#B2B2B2"}}></i>&nbsp; Visores</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Publicidad" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-clock" style={{color:"#B2B2B2"}}></i>&nbsp; Publicidad</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Paquetes" className="menu-link">
<div class="menu-text"> <i class="fas fa-users-cog" style={{color:"#B2B2B2"}}></i>&nbsp; Paquetes</div>
</Link>
</div>
*/}
</div>
</div>
        
   
        </>

        : ""
        } 

        
{ theUser.tipo_usuario =="admin"
     ?<>

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-map-signs"></i>
</div>
<div class="menu-text">Zonas</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Zonas_azules" className="menu-link">
<div class="menu-text"> <i class="fa fa-street-view" style={{color:"#B2B2B2"}}></i>&nbsp; Zonas azules</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Asignar_bahias" className="menu-link">
<div class="menu-text"> <i class="fa fa-map-pin" style={{color:"#B2B2B2"}}></i>&nbsp;&nbsp;    Asignar Bahías</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Modificar_bahias" className="menu-link">
<div class="menu-text"> <i class="fa fa-globe" style={{color:"#B2B2B2"}}></i>&nbsp;&nbsp;    Modificar Bahías</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Sub_zonas" className="menu-link">
<div class="menu-text"> <i class="fa fa-user-plus" style={{color:"#B2B2B2"}}></i>&nbsp;Asignar sub zonas</div>
</Link>
</div>


</div>
</div>
        
   
        </>

        : ""
        } 

{ theUser.tipo_usuario =="admin"
     ?<>

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-podcast"></i>
</div>
<div class="menu-text">Tarifa</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Tarifas" className="menu-link">
<div class="menu-text"> <i class="fa fa-users" style={{color:"#B2B2B2"}}></i>&nbsp; Actualizar Tarifa</div>
</Link>
</div>


</div>
</div>
        
   
        </>

        : ""
        } 


{ theUser.tipo_usuario =="admin"
     ?<>

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fas fa-user-check"></i>
</div>
<div class="menu-text">Notificaciónes</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Notificaciones" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-circle" style={{color:"#B2B2B2"}}></i>&nbsp; Supervisores</div>
</Link>
</div>


</div>
</div>
        
   
        </>

        : ""
        } 



{ theUser.tipo_usuario =="admin"
     ?<>

    <div class="menu-item has-sub ">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-table"></i>
</div>
<div class="menu-text">Informes</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Informe_general" className="menu-link">

<div class="menu-text"> <i class="fas fa-file-csv" style={{color:"#B2B2B2"}}></i>&nbsp; Informe General</div>
</Link>
</div>
{/*<div class="menu-item active">
<Link to="/Operadores" className="menu-link">
<div class="menu-text"> <i class="fas fa-users" style={{color:"#B2B2B2"}}></i> &nbsp;Operadores</div>
</Link>
</div>
      */}

</div>
</div>
        
   
        </>

        : ""
        } 


    
    
    <div className="menu-item d-flex">
    <a href="javascript:;" className="app-sidebar-minify-btn ms-auto" data-toggle="app-sidebar-minify"><i className="fa fa-angle-double-left"></i></a>
    </div>
    
    </div>
    </div>
    </div>

<div className="app-sidebar-bg"></div>
<div className="app-sidebar-mobile-backdrop"><a href="#" data-dismiss="app-sidebar-mobile" className="stretched-link"></a></div>

</>

  /* <nav classNameName="navbar  main-sidebar sidebar-light-primary elevation-4 " >
  
  
   <center>
  <a href="#" classNameName="brand-link">
      <img src={LogoMenu} alt="ODIN" width="50px" />
      <span classNameName="brand-text font-weight-light"></span>
    </a>   </center>  

   
    <div classNameName=" scrollb ">
      <div classNameName="user-panel mt-3 pb-3 mb-3 d-flex">
        
        <div classNameName="info">
          <br/>
          <h6  classNameName="d-block"><b>{theUser.name}</b></h6>

          
          <button classNameName="btn btn-danger " onClick={logoutUser} > Salir <i classNameName="fas fa-sign-out-alt"></i></button>
            
            
            
        </div>
      </div>

      <nav classNameName="mt-2">
    
   
      <ul classNameName="nav navbar-nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">         
      

{ theUser.rol =="admin"
          ?<><li classNameName="nav-item" >
          <Link to="/Shoping" classNameName="nav-link">
          
          <i className="fa fa-shopping-cart fa-2x" style={{color:"#14CF01"}}></i>
            <p><font size="3">
               &nbsp; Shoping
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Productos" classNameName="nav-link">
          
          <i className="fa fa-server fa-2x" style={{color:"#17A6FB"}}></i>
            <p><font size="3">
               &nbsp; Mis productos
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>
          
          <li classNameName="nav-item" >
          <Link to="/Odin" classNameName="nav-link">
          
          <i className="fas fa-cog fa-spin fa-2x" style={{color:"#17A6FB"}}></i>
            <p><font size="3">
               &nbsp; ODÍN IA
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Esquemas" classNameName="nav-link">
          
          <i className="fa fa-database fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Esquemas
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Proyectos" classNameName="nav-link">
          
          <i className="fa fa-file-code-o fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Proyectos
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Clones" classNameName="nav-link">
          
          <i className="fa fa-cubes fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Clones
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>


        <li classNameName="nav-item" >
          <Link to="/Manuales" classNameName="nav-link">
          
          <i className="fa fa-file-pdf-o fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Manuales
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li> 

   

    </>

: ""
}







      </ul>
      

     

      <ul classNameName="nav navbar-nav nav-pills nav-sidebar flex-column">
      
        
      </ul>
    
    </nav>
  </div>

</nav> */
       
    )

    
  }

 


export default Menu