import React, {useContext, useState} from 'react'
import {MyContext} from './MyContext';

import Logo from '../../components/assets/login/logo.jpg';

import '../../App.css';


function Login(){

    const [recuperar_pass, setRecuperar_pass] = useState("VOLVER A INICIO");

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }


    const recuperar_password= (e) => {
      // console.log(e.target.value) // si || no
      setRecuperar_pass(e.target.value)
    }

    const {toggleNav,loginUser,isLoggedIn} = useContext(MyContext);

    const initialState = {
        userInfo:{
            email:'',
            clave:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }



    return(
<>
<div id="loader" className="app-loader">
{/*<span className="spinner"></span> */}
</div>


<div id="app" className="app">

<div className="login login-with-news-feed">

<div className="news-feed">
<div className="news-image Fondo" ></div>

</div>


<div className="login-container">

<div className="login-header mb-30px" style={{marginLeft: "30%"}}>
<div className="brand">
<div className="d-flex align-items-center">
<img src={Logo} width="150px" />
</div>

</div>
{/*<div className="icon">
<i className="fa fa-sign-in-alt"></i>
    </div> */}
</div>


<div className="login-content">   
{recuperar_pass === "VOLVER A INICIO" && (
<form className="fs-13px" onSubmit={submitForm} noValidate>
<div className="form-floating mb-15px">
<input name="email" type="text" className="form-control h-45px fs-13px" placeholder="Usuario"
 value={state.userInfo.email} onChange={onChangeValue} autoComplete="off" />
<label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email</label>
</div>
<div className="form-floating mb-15px">
<input name="clave" type="password" className="form-control h-45px fs-13px" placeholder="Password" autoComplete="off"
 value={state.userInfo.clave} onChange={onChangeValue} />
<label for="password" className="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
</div>

<div className="mb-15px">
<button type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">Iniciar Sesión</button>
</div>
<div className="mb-40px pb-40px text-dark">
 {/*<a href="#" className="text-primary" style={{textDecoration: 'none'}}>RECUPERAR <b>CONTRASEÑA</b></a> */}
 <input
            type="button"
           
            value="RECUPERAR CLAVE"    
            className="btn btn-default"       
            onClick={recuperar_password}
          /> 
</div> 




<center><h4>{errorMsg}</h4></center>
{successMsg}
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados parqueoenvia.com
</div>
</form>
  )}

<div className="col-sm-12">
        {recuperar_pass === "RECUPERAR CLAVE" && (
            <>
            <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/portal.php" target="_parent" name="reset" noValidate>
          <div className="form-floating mb-15px">
          <input name="email" type="email" className="form-control h-45px fs-13px" placeholder="Usuario"
            autoComplete="off" />
          <label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email</label>
          </div>
         
          <div className="mb-15px">
          <button type="submit" onClick={loadData} disabled={loading} className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">Recuperar Clave</button>
          </div>
        

          <div className="mb-40px pb-40px text-dark">
 
 <input
            type="button"
           
            value="VOLVER A INICIO"    
            className="btn btn-default"       
            onClick={recuperar_password}
          /> 
</div> 
          
          
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados parqueoenvia.com
</div>
          </form>
          </>
          
        )}
      </div>

</div>

</div>

</div>

</div>



<a href="javascript:;" className="btn btn-icon btn-circle btn-success btn-scroll-to-top" data-toggle="scroll-to-top"><i class="fa fa-angle-up"></i></a>


</>
      /*  <div className="login-4l">
            <div className="containerl-fluid">
                <div className="rowl login-boxl">
                    <div className="col-lg-6 form-sectionl">
                        <div class="form-innerl">
                            
                                <h1 style={{color:"white"}}>ODÍN INTERFACE</h1>
                            
                            <h3>
                                Inteligencia Artificial
                            </h3>
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Hola diego,</strong> En que te puedo ayudar!
                                 
                                {errorMsg}
                                {successMsg}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            <form onSubmit={submitForm} noValidate>
                                <div class="form-group position-relative clearfix">
                                    <input name="email" type="email" className="form-control"
                                     placeholder="Usuario" aria-label="Email Address"  
                                     value={state.userInfo.email} onChange={onChangeValue} autoComplete="off" />

                                    <div class="login-popover login-popover-abs" data-bs-toggle="popover" data-bs-trigger="hover" title="Solo Usuarios Creados" data-bs-content="Usuarios creados por ODÍN INTERFACE">
                                        <i class="fa fa-info-circle"></i>
                                    </div>
                                </div>
                                <div class="form-group clearfix position-relative password-wrapper">
                                    <input name="clave" type="password" className="form-control"
                                     autocomplete="off" placeholder="Contraseña" aria-label="Password"
                                     value={state.userInfo.clave} onChange={onChangeValue} />
                                    <i class="fa fa-eye password-indicator" ></i>
                                </div>
                                <div class="form-group mb-0 clearfix">
                                    <button type="submit" class="btn btn-lg btn-primary btn-theme">Ingresar</button>
                                    <a href="#" class="forgot-password float-end">Recuperar clave</a>


                                   
                                    
                                </div>
                                <div class="extra-login clearfix">
                                    <span>Todos los derechos reservados - Giovanny Chavez Marin</span>
                                </div>
                                <div class="clearfix"></div>
                                
                            </form>
                            <div class="clearfix"></div>
                           
                        </div>
                    </div>
                    <div class="col-lg-6 bg-img clip-home h-100"></div>
                </div>
            </div>
        </div>

*/


    );
}

export default Login;