import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  modulo: "",
  deporte: "",
  subdeporte: "",
  pais: "",
  departamento: "",
  ciudad: ""
};

function Ver_publicidad () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/publicidad/publicidad.php/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    name:'',
    nit:'',
    ciudad:'',
    licencia:'',
    email:'',
    clave:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("valorhora", frameworkSeleccionado.valorhora);

    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.valorhora= frameworkSeleccionado.valorhora;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("pais", frameworkSeleccionado.pais);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  /*const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/


  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      //sortDirection:"asc"
      
    },
    
    {
       
      title:"NOMBRE_PUBLICIDAD",
      field:"nombre_publicidad"
      
      
      
    },


    

    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>




<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">PUBLICIDAD</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><i class="fa-solid fa-dollar-sign fa-2x"></i></>}
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'build',
        tooltip: 'Editar Tarifa',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
 /* {
   icon : 'block',
   tooltip: 'Suspender',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }*/
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea suspender el punto de venta {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">ACTUALIZAR PUBLICIDAD</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <form method="post" action="https://parqueoenvia.com/apiParqueoenvia/publicidad_img/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>
   <div className="row">
   
   <center><div class="col-12">
     <label>PUBLICIDAD ACTUAL:</label>
     <br/>
     { frameworkSeleccionado.ruta !=""
     ?
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/"+frameworkSeleccionado.ruta}     
     width="320px" />
     : ""
    } 

    { frameworkSeleccionado.ruta2 !=""
     ?
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/"+frameworkSeleccionado.ruta2}     
     width="320px" />
     : ""
    } 

   { frameworkSeleccionado.ruta3 !=""
     ?
     <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/"+frameworkSeleccionado.ruta3}     
     width="320px" />
     : ""
    } 
     <br/>
     </div>

     <div class="col-12">
     <br/>
     <label>Nombre de la publicidad:</label>
     <br/>
     <input type="text" placeholder="Nombre publicidad" className="form-control " name="nombre_publicidad" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nombre_publicidad}  />

     </div>

     <div class="col-12">
     <br/>

     <h6>Nota: La resolución debe ser de 600px (ancho) x 400px (alto) y el peso recomendado debe ser máximo de 100kb </h6>

        </div>

     <div class="col-12">
     <br/>
     { frameworkSeleccionado.ruta !=""
     ?
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
         : ""
        }  


{ frameworkSeleccionado.ruta2 !=""
     ?
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto3" name="txtFoto3" type="file"
                accept="image/*"
                onChange={handleChange} />
                <br/>
              </div>
         : ""
        }  


{ frameworkSeleccionado.ruta3 !=""
     ?
     <div class="form-group">

                <label class="form-label" for="customFile">Cambiar publicidad</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto4" name="txtFoto4" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
         : ""
        }  




     </div>




     </center> 

     

   
     <div style={{display:"none"}}>
  <input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />

  </div>

   </div>
   <br/>

   <button className="btn btn-primary w-100"><i class="fa-solid fa-pen-to-square"></i> Actualizar Publicidad</button>{" "}
   </form>
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">PUNTO DE VENTA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-8">

   <label>P. Venta:</label>
   <br/>
         <input type="text" className="form-control"  name="name" onChange={handleChange}  />
     </div>

     <div class="col-4">
     <label>NIT</label>
     <br/>
     <input type="number" className="form-control" name="nit" onChange={handleChange}  />
     
     </div>
     
     
     <div class="col-4">
     <br/>
     <label>Ciudad:</label>
     <br/>
     <select name="ciudad" class="form-control"  onChange={handleChange} >
     <option value='no' selected>Seleccione</option>
                    <option value="Ibague">Ibagué</option>
                    <option value="Espinal">Espinal</option>
                    
                  </select>  
                  <br />
     </div>
     
     <div class="col-8">
     <br/>
     <label>Dirección:</label>
     <br/>
     <input type="text" name="licencia" class="form-control"  onChange={handleChange} />
     
                    
                 
                  <br />
     </div>

     <div class="col-7">
     <label>Email:</label>
     <br/>
     <input type="email" className="form-control" name="email" onChange={handleChange}  />
     
     </div>

     <div class="col-5">
     <label>Contraseña:</label>
     <br/>
     <input type="password"  className="form-control" name="clave" onChange={handleChange}  />
     <br/>
     </div>
     
    

     </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Ver_publicidad
